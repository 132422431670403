import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import styles from "./driftSection.module.scss";

export default function DriftSection() {
    const data = useStaticQuery(graphql`
      query {
        yellow: file(relativePath: { eq: "Yellow.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 300) {
                   ...GatsbyImageSharpFluid
                   ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        blue: file(relativePath: { eq: "Blue.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 300) {
                   ...GatsbyImageSharpFluid
                   ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        red: file(relativePath: { eq: "Red.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 300) {
                   ...GatsbyImageSharpFluid
                   ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        artist: file(relativePath: { eq: "Artists.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 200) {
                   ...GatsbyImageSharpFluid
                   ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        user: file(relativePath: { eq: "User.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 200) {
                   ...GatsbyImageSharpFluid
                   ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        influencers: file(relativePath: { eq: "Influencers.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 200) {
                   ...GatsbyImageSharpFluid
                   ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
    }`)
    return (
        <div className="container drift-post">
            <div className="row">
                <div className="col-12 col-md-6 text-center">
                    <div className="position-relative">
                        <Img fluid={data.yellow.childImageSharp.fluid} className={styles.rotate} alt="yellow-dots"/>
                        <Img className={`position-absolute ${styles.stable}`} fluid={data.artist.childImageSharp.fluid}
                             alt="album1"/>
                    </div>
                </div>
                <div className="col-12 col-md-6 pt-md-5 text-left align-self-center">
                    <h3 className="font-weight-bold mb-3">Artists</h3>
                    <p>Do you want the world to listen to your music?</p>
                    <p>
                        Join Bliiink & be a musician that stands out from the crowd!
                        We provide you with assured feedback from leading music experts that will drive
                        reach and plays onto your music. Our featured songs get thousands to million
                        plays and receive the acknowledgement it deserves.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 order-sm-2 text-center">
                    <div className="position-relative">
                        <Img fluid={data.red.childImageSharp.fluid} className={styles.rotate} alt="red-dots"/>
                        <Img className={`position-absolute ${styles.stable}`}
                             fluid={data.influencers.childImageSharp.fluid}
                             alt="album1"/>
                    </div>
                </div>
                <div className="col-md-6 pt-md-5 align-self-center order-sm-1">
                    <h3 className="font-weight-bold mb-3">Tastemakers</h3>
                    <p>Become a Bliiink influencer & get the Music You Want!</p>
                    <p>
                        Spot the artist and help to shape the future of music. Bliink is the source for fresh music
                        covering almost all the
                        trending genres from emerging artists. Here you have a chance to showcase your expertise in
                        music with your target audience.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="position-relative">
                        <Img fluid={data.blue.childImageSharp.fluid} className={styles.rotate} alt="blue-dots"/>
                        <Img className={`position-absolute ${styles.stable}`}
                             fluid={data.user.childImageSharp.fluid}
                             alt="album1"/>
                    </div>
                </div>
                <div className="col-md-6 pt-md-5 text-left align-self-center">
                    <h3 className="font-weight-bold mb-3">Music Lovers</h3>
                    <p>Listen to Bliiink Music & Stay Musically-driven!</p>
                    <p>
                        From popular tracks to new music to debut album that
                        spans the entire spectrum of musical genres
                        We discover the most promising and emerging artists every
                        day so that you get to listen to trending music and cheer yourself
                        up musically.
                    </p>
                </div>
            </div>
        </div>
    );
}
