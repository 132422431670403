import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql, Link} from "gatsby";
import ArtistSlider from "../components/artistSlider";
import DriftSection from '../components/driftSection';
import Showcase from "../components/showcase";
import Img from "gatsby-image";
import ImageSlider from "../components/imageSlider";
import BackgroundImage from "gatsby-background-image";
import Newsletter from "../components/newsletter";
import HomeVideo from "../images/iStock-1181815669.mp4";
import styles from "../components/imageSlider.module.scss";
import InfluencerList from "../components/influencerList";
import FeaturedBlog from "../components/featuredBlog";
import Benefits from "../components/benefits";
import ArtistSpotlightList from "../components/artist-spotlight-list";

export default function IndexPage({data}) {
    console.log(data)
    return (
        <Layout>
            <SEO title="Home" image={data.bliiinkLogo.childImageSharp.fixed}/>
            <section className="home-container">
                <div className="video-container">
                    <video
                        src={HomeVideo}
                        className="video d-none d-lg-block"
                        autoPlay
                        loop
                        playsInline
                        muted
                    />
                </div>
                <div className="h-100 container position-relative">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-12 col-lg-6 col-xl-5 pt-5 mt-5 text-center text-lg-left">
                            <h1 className="display-lg-4 text-white font-weight-bolder mb-4">
                                <span className="text-uppercase"><span className={`${styles.green}`}>Launch</span> Your Career In The Music Industry</span>
                            </h1>
                            <p>
                                <h5><strong>We Make It Happen</strong></h5>
                            </p>
                            <p className="text-white opacity-8">
                                <strong>Find, Play, Like, Earn, and Repeat!</strong>
                            </p>
                            <div className="mt-5 mb-5 mb-md-0">
                                <a target="_blank" href="https://open.bliiink.co.uk/">
                                    <button type="button" className={`${styles.btn} btn btn-dark btn-lg`}>
                                        Join Free - No Fee
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-7 mt-7 mt-lg-0 text-center d-none d-md-block">
                            <div>
                                <Img fixed={data.computer.childImageSharp.fixed} alt="computer"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<ImageSlider*/}
                {/*    firstBanner={data.firstBanner.childImageSharp.fluid}*/}
                {/*    secondBanner={data.secondBanner.childImageSharp.fluid}*/}
                {/*    thirdBanner={data.thirdBanner.childImageSharp.fluid}*/}
                {/*    appleLogo={data.appleLogo.childImageSharp.fixed}*/}
                {/*    googleLogo={data.googleLogo.childImageSharp.fixed}*/}
                {/*    phone={data.phone.childImageSharp.fixed}*/}
                {/*    computer={data.computer.childImageSharp.fixed}*/}
                {/*/>*/}
            </section>
            <section className="second-section py-5" id="about-us">
                <BackgroundImage
                    Tag="div"
                    fluid={data.guitarBg.childImageSharp.fluid}>
                    <div className="container">
                        <div className="text-center pb-sm-5" style={{paddingTop: '100px'}}>
                            <h2>See what <span className={`${styles.green}`}>B</span>liiink can do for you</h2>
                        </div>
                        <div className="row justify-content-center welcome-section pt-5">
                            <div className="col-lg-5 col-md-6">
                                <p className="pb-4">
                                    Bliiink Music is a community of artists, influencers, and music lovers who
                                    connect with the power of fresh music. We bring opportunities for
                                    upcoming talented artists while supporting the creative needs of our influencers.
                                </p>
                                <div>
                                    <Img fluid={data.welcomeLeftImg.childImageSharp.fluid}/>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6 pt-5">
                                <p className="pb-4">
                                    We are committed to providing a spotlight to the emerging talents of the music
                                    industry by sharing their work with our network of top-notch influencers.
                                    We work with Artists and Curators to create and promote their music that will win
                                    the hearts of users.
                                </p>
                                <div>
                                    <Img fluid={data.welcomeRightImg.childImageSharp.fluid}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </section>
            <section className="third-section pt-5">
                <div className="container">
                    <div className="text-center pb-sm-5">
                        <h2>We Are <span className={`${styles.green}`}>B</span>liiink!</h2>
                        <h5>A web3 music accelerator and curated streaming platform.</h5>
                    </div>
                    <DriftSection/>
                </div>
            </section>
            {/*<section className="fourth-section">*/}
            {/*    <BackgroundImage*/}
            {/*        Tag="div"*/}
            {/*        fluid={data.featuredArtistBg.childImageSharp.fluid}>*/}
            {/*        <div className="container featuredArtistSection">*/}
            {/*            <div className="text-center pb-sm-5">*/}
            {/*                <h2>Featured Artist</h2>*/}
            {/*            </div>*/}
            {/*            <ArtistSlider*/}
            {/*                firstArtist={data.firstArtist.childImageSharp.fixed}*/}
            {/*                secondArtist={data.secondArtist.childImageSharp.fixed}*/}
            {/*                thirdArtist={data.thirdArtist.childImageSharp.fixed}*/}
            {/*                fourthArtist={data.fourthArtist.childImageSharp.fixed}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </BackgroundImage>*/}
            {/*</section>*/}
            <section className="sixth-section pt-5 mt-5">
                <Benefits/>
            </section>
            <section className="sixth-section pt-5 mt-5">
                <InfluencerList/>
            </section>
            <section className="fifth-section py-5">
                <div className="container pt-5">
                    <div className="text-center pb-lg-4 pt-3">
                        <h2>Music Business & Marketing</h2>
                    </div>
                    <div className="row">
                        <FeaturedBlog articles={data.allWpPost.nodes}/>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <Link to="/blogs/">
                                <button type="button" className={`${styles.btn} btn btn-dark btn-lg`}>
                                    Read More
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <ArtistSpotlightList/>
            <section className="seven-section py-5" id="contact-us">
                <BackgroundImage
                    Tag="div"
                    fluid={data.formBg.childImageSharp.fluid}>
                    <Newsletter/>
                </BackgroundImage>
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
  query {
  allWpPost(sort: { fields: [date], order: DESC }, limit: 6, filter: {categories: {nodes: {elemMatch: {id: {eq: "dGVybTox"}}}}}) {
      nodes {
        title
        excerpt
        slug
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {  
            remoteFile {
              childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
            }
          }  
        }
      }
    },
    bliiinkLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 145) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    appleLogo: file(relativePath: { eq: "apple.png" }) {
        childImageSharp {
            fixed(quality: 100, width: 168) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    googleLogo: file(relativePath: { eq: "google-play-badge.png" }) {
        childImageSharp {
            fixed(quality: 100, width: 188) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    welcomeLeftImg: file(relativePath: { eq: "portrait_of_a_cute_woman_dj_with_headphones_play__1040815071__gaOgMyESBXyR__sd_dreamlike-photoreal-2-0__dreamlike-art.jpg" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    welcomeRightImg: file(relativePath: { eq: "portrait_of_a_male_hiphop_artist_excited_express__85524__gisBgNOz8oPw__sd_dreamlike-photoreal-2-0__dreamlike-art.jpg" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    topInfulencers: file(relativePath: { eq: "top-Infulencers.jpg" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    featuredArtistBg: file(relativePath: { eq: "BG-2.png" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    guitarBg: file(relativePath: { eq: "BG-1.png" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    formBg: file(relativePath: { eq: "BG-3.png" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    firstBanner: file(relativePath: { eq: "Banner-1.jpg" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    secondBanner: file(relativePath: { eq: "Banner-2.jpg" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    thirdBanner: file(relativePath: { eq: "Banner-3.jpg" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    phone: file(relativePath: { eq: "Phone.png" }) {
        childImageSharp {
            fixed(quality: 100, height: 500) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    welcomeBanner: file(relativePath: { eq: "BG-1.png" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    firstArtist: file(relativePath: { eq: "home-6-album-7-1.jpg" }) {
        childImageSharp {
            fixed(quality: 100, height: 260) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    secondArtist: file(relativePath: { eq: "home-6-album-3-1.jpg" }) {
        childImageSharp {
            fixed(quality: 100, height: 260) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    thirdArtist: file(relativePath: { eq: "home-6-album-4-1.jpg" }) {
        childImageSharp {
            fixed(quality: 100, height: 260) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    fourthArtist: file(relativePath: { eq: "dischography-imag-14.jpg" }) {
        childImageSharp {
            fixed(quality: 100, height: 260) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    computer: file(relativePath: { eq: "image_2020_11_11T09_11_47_587Z.png" }) {
        childImageSharp {
            fixed(quality: 100, height: 400) {
                ...GatsbyImageSharpFixed
            }
        }
    }
  }
`
