import React, {useState} from "react";
import Alert from 'react-bootstrap/Alert';

export default function Newsletter() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [facebook, setFacebook] = useState('');
    const [showFacebook, setShowFacebook] = useState(false);
    const [twitter, setTwitter] = useState('');
    const [showTwitter, setShowTwitter] = useState(false);
    const [youtube, setYoutube] = useState('');
    const [showYoutube, setShowYoutube] = useState(false);
    const [tiktok, setTiktok] = useState('');
    const [showTiktok, setShowTiktok] = useState(false);
    const [instagram, setInstagram] = useState('');
    const [showInstagram, setShowInstagram] = useState(false);
    const [successShow, setSuccessShow] = useState(false);

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleSubmit = e => {
        const formdata = new FormData();
        formdata.append("your-name", name);
        formdata.append("your-email", email);

        const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch("http://blog.bliiink.co.uk/wp-json/contact-form-7/v1/contact-forms/15/feedback", requestOptions)
            .then((response) => response.json())
            .then(data => {
                alert(data.message)
            })
            .catch(error => alert(error))
            .finally(() => {
                setName('')
                setEmail('')
            });

        e.preventDefault();
    };

    const handleClick = e => {
        switch (e.target.value) {
            case 'facebook':
                setShowFacebook(!showFacebook);
                break;
            case 'twitter':
                setShowTwitter(!showTwitter);
                break;
            case 'instagram':
                setShowInstagram(!showInstagram);
                break;
            case 'youtube':
                setShowYoutube(!showYoutube);
                break;
            case 'tiktok':
                setShowTiktok(!showTiktok);
                break;
        }
    }

    return (
        <div className="container text-center newsletter-section">
            <div className="text-center pb-lg-4">
                <h2>Subscribe</h2>
                <p className="subtitle text-uppercase">
                    Sign up to be part of the beta program
                </p>
            </div>
            <div className="row">
                <div className={`mx-auto p-4 col-md-10`}>
                    <form onSubmit={handleSubmit} method="post">
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <input
                                    className="form-control mb-2 mr-sm-2 field-email field-type-text"
                                    placeholder="Email"
                                    type="email"
                                    name="EMAIL"
                                    id="EMAIL"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required="required"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <input
                                    className="form-control field-name field-type-text"
                                    placeholder="Name"
                                    type="text"
                                    required="required"
                                    name="NAME"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    id="NAME"
                                />
                            </div>
                        </div>
                        <div className="box-footer">
                            <div className="pull-right">
                                <input
                                    type="submit"
                                    className="btn btn-dark btn-flat"
                                    name="yt0" value="Subscribe"/>
                            </div>
                            <div className="clearfix"></div>
                        </div>

                    </form>
                    {/*{successShow &&
                    <Alert variant="success" onClose={() => setSuccessShow(false)} dismissible>
                        <Alert.Heading>Success</Alert.Heading>
                        <p>
                            Thank you! Your submission has been received!
                        </p>
                    </Alert>
                    }
                    <form name="contact" onSubmit={handleSubmit} data-netlify="true"
                          data-netlify-honeypot="bot-field">
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <input
                                    type="text"
                                    className="form-control mb-2 mr-sm-2"
                                    id="inlineFormInputName2"
                                    placeholder="Name"
                                    onChange={e => setName(e.target.value)}
                                    name="name"
                                    required="required"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    onChange={e => setEmail(e.target.value)}
                                    id="inlineFormInputGroupUsername2"
                                    placeholder="Email Address"
                                    required="required"
                                />
                            </div>
                        </div>
                        <select className="custom-select mb-2 mr-sm-2" name="type" id="inlineFormCustomSelectPref"
                                onChange={e => setType(e.target.value)} required="required">
                            <option value="">Please select an option</option>
                            <option value="Tastemaker">Tastemaker/Influencer</option>
                            <option value="Artist">Artist</option>
                            <option value="Music_Lovers">Music Lovers</option>
                            <option value="DJ">DJ</option>
                            <option value="Investor">Investor</option>
                        </select>
                        <label>Social Medium Handles</label>
                        <div className="form-group">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" onClick={event => handleClick(event)}
                                       type="checkbox"
                                       id="inlineCheckbox1" value="facebook"/>
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Facebook</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" onClick={event => handleClick(event)}
                                       type="checkbox"
                                       id="inlineCheckbox2" value="twitter"/>
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Twitter</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" onClick={event => handleClick(event)}
                                       type="checkbox"
                                       id="inlineCheckbox3" value="instagram"/>
                                <label className="form-check-label" htmlFor="inlineCheckbox3">Instagram</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" onClick={event => handleClick(event)}
                                       type="checkbox"
                                       id="inlineCheckbox4" value="youtube"/>
                                <label className="form-check-label" htmlFor="inlineCheckbox4">Youtube</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" onClick={event => handleClick(event)}
                                       type="checkbox"
                                       id="inlineCheckbox5" value="tiktok"/>
                                <label className="form-check-label" htmlFor="inlineCheckbox5">Tiktok</label>
                            </div>
                        </div>
                        {showFacebook &&
                        <div className="input-group mb-2 mr-sm-2">
                            <input
                                type="text"
                                name="facebook"
                                className="form-control"
                                onChange={e => setFacebook(e.target.value)}
                                placeholder="Facebook Handle"
                            />
                        </div>
                        }
                        {showTwitter &&
                        <div className="input-group mb-2 mr-sm-2">
                            <input
                                type="text"
                                name="twitter"
                                className="form-control"
                                onChange={e => setTwitter(e.target.value)}
                                placeholder="Twitter Handle"
                            />
                        </div>
                        }
                        {showInstagram &&
                        <div className="input-group mb-2 mr-sm-2">
                            <input
                                type="text"
                                name="instagram"
                                className="form-control"
                                onChange={e => setInstagram(e.target.value)}
                                placeholder="Instagram Handle"
                            />
                        </div>
                        }
                        {showYoutube &&
                        <div className="input-group mb-2 mr-sm-2">
                            <input
                                name="youtube"
                                className="form-control"
                                onChange={e => setYoutube(e.target.value)}
                                placeholder="Youtube Handle"
                            />
                        </div>
                        }
                        {showTiktok &&
                        <div className="input-group mb-2 mr-sm-2">
                            <input
                                name="tiktok"
                                className="form-control"
                                onChange={e => setTiktok(e.target.value)}
                                placeholder="Tiktok Handle"
                            />
                        </div>
                        }
                        <input type="hidden" name="form-name" value="contact"/>
                        <button type="submit" className="btn btn-dark mb-2">Submit</button>
                    </form>*/}
                </div>
            </div>
        </div>
    );
}
