import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import styles from './influencerList.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination, Navigation, Autoplay} from "swiper";
SwiperCore.use([Autoplay]);

export default function InfluencerList() {
    const data = useStaticQuery(graphql`
      query {
        artist1: file(relativePath: { eq: "artist1.jpeg" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist2: file(relativePath: { eq: "artist2.jpeg" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist3: file(relativePath: { eq: "artist3.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist4: file(relativePath: { eq: "artist4.jpeg" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist5: file(relativePath: { eq: "artist5.jpeg" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist6: file(relativePath: { eq: "EdgarAllan.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist7: file(relativePath: { eq: "Tinnitist.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist8: file(relativePath: { eq: "NeonMusic.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist9: file(relativePath: { eq: "Musicreviewingsquirrel.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist10: file(relativePath: { eq: "Soundbest.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist11: file(relativePath: { eq: "PopPassion.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist12: file(relativePath: { eq: "resized.jpg" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist13: file(relativePath: { eq: "sterohearts.jpg" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist14: file(relativePath: { eq: "honk.jpeg" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist15: file(relativePath: { eq: "pink-wafer-logo.png.webp" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist16: file(relativePath: { eq: "Wolfinasuit.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist17: file(relativePath: { eq: "DriveMusic.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist18: file(relativePath: { eq: "MusicOnTheRox.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist19: file(relativePath: { eq: "newartist1.jpeg" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist20: file(relativePath: { eq: "Alfitude.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist21: file(relativePath: { eq: "Littlemisschatterbox.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        artist22: file(relativePath: { eq: "plasticmag.png" }) {
            childImageSharp {
                fixed(quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }`)

    const influencerData = [
        {
            name: "Wolf In A Suit",
            image: data.artist16.childImageSharp.fixed,
            description: "Wolf In A Suit: Music blog that was established in 2016",
        },
        {
            name: "tinnitist.com",
            image: data.artist7.childImageSharp.fixed,
            description: "Canada's most-followed music critic.",
        },
        {
            name: "Chris Riquez",
            image: data.artist1.childImageSharp.fixed,
            description: "Social media influencer",
        },
        {
            name: "neonmusic.co.uk",
            image: data.artist8.childImageSharp.fixed,
            description: "Renowned independent UK music discovery publication.",
        },
        {
            name: "Theophilus Gokana",
            image: data.artist2.childImageSharp.fixed,
            description: "Content Creator/Choreographer",
        },
        {
            name: "Alfitude New music",
            image: data.artist20.childImageSharp.fixed,
            description: "Globally recognised as one the most respected new music tastemaker sites since 2010",
        },
        {
            name: "Sound Best",
            image: data.artist10.childImageSharp.fixed,
            description: "Sound Best is an Artist Management - Record Company",
        },
        {
            name: "Sunny Jay",
            image: data.artist3.childImageSharp.fixed,
            description: "Dancer/choreographer",
        },
        {
            name: "poppassionblog.com",
            image: data.artist11.childImageSharp.fixed,
            description: "Pop Passion is all the latest for new, rising, and established artists",
        },
        {
            name: "Rafaelle Tala",
            image: data.artist4.childImageSharp.fixed,
            description: "Zumba Pro Dancer/choreographer",
        },
        {
            name: "Stereohearts",
            image: data.artist13.childImageSharp.fixed,
            description: "Stereohearts RnB YouTube's biggest and best R&B music channel",
        },
        {
            name: "Afro Champ",
            image: data.artist5.childImageSharp.fixed,
            description: "Choreographer | Dance instructor",
        },
        {
            name: "Honk Magazine",
            image: data.artist14.childImageSharp.fixed,
            description: "Honk Magazine is an Online entertainment magazine",
        },
        {
            name: "Anya Roscoe",
            image: data.artist21.childImageSharp.fixed,
            description: "UK Influencer",
        },
        {
            name: "Edgar Allen",
            image: data.artist6.childImageSharp.fixed,
            description: "The Poets are two rock noir artists inspired by the works of Edgar Allen Poe",
        },
        {
            name: "Plastic",
            image: data.artist22.childImageSharp.fixed,
            description: "Entertainment music blog",
        },
        {
            name: "Music reviewing squirrel",
            image: data.artist9.childImageSharp.fixed,
            description: "Music Enthusiasts with great reviews",
        },
        {
            name: "Stereofox",
            image: data.artist12.childImageSharp.fixed,
            description: "Stereofox is a collective of creative people who love music",
        },
        {
            name: "Pink Wafer Club",
            image: data.artist15.childImageSharp.fixed,
            description: "Pink Wafer Club Music blog & magazine",
        },
        {
            name: "Drive Music",
            image: data.artist17.childImageSharp.fixed,
            description: "Drive Music: Aims to promote music and artists through the visuals of driving.",
        },
        {
            name: "Music On The Rox",
            image: data.artist18.childImageSharp.fixed,
            description: "Artist promotions/Reviews around the world",
        },
        {
            name: "1111 Crew",
            image: data.artist19.childImageSharp.fixed,
            description: "The Hollywood Six #1 Visionaire Source of fresh music.",
        }]

    return (

        <div className="container">
            <div className="text-center pb-lg-4 pt-3">
                <h2>Trusted Tastemakers</h2>
            </div>
            <div className={`d-flex ${styles.imageHolder} py-4 text-center`}>
                <Swiper
                    // slidesPerView={4}
                    // spaceBetween={30}
                    // slidesPerGroup={4}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {influencerData.map((item, index) =>
                        <SwiperSlide>
                            <Img className="rounded-circle" fixed={item.image} alt="album1"/>
                            <div className="py-2 py-md-0 text-center">
                                <h5 className={styles.heading}>{item.name}</h5>
                                <small>{item.description}</small>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    )
}
