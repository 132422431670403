import {Swiper, SwiperSlide} from 'swiper/react'
import React from "react"
import SwiperCore, {Navigation} from 'swiper';
import Img from "gatsby-image";

SwiperCore.use([Navigation]);
export default function ArtistSlider({firstArtist, secondArtist, thirdArtist, fourthArtist}) {
    const artistData = [{
        title: "Neon Blue",
        image: firstArtist
    }, {
        title: "Lucia Woo",
        image: secondArtist
    }, {
        title: "Leopold Wron",
        image: thirdArtist
    }, {
        title: "Electo Vibes",
        image: fourthArtist
    }]

    return (
        <Swiper
            spaceBetween={20}
            slidesPerView={3}
            breakpoints={{
                320: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 4
                },
            }}
            navigation
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            {artistData.map((artist) => (
                <SwiperSlide key={artist.title}>
                    <div className="artist-item">
                        <Img fixed={artist.image}
                             className="artist-slider-img"/>
                        <div className="d-flex flex-column h-100 pt-2">
                            <p className="mb-0 text-white font-weight-bold">{artist.title}</p>
                            <p className="mb-0 text-white font-weight-bold">Bliiink music 2020</p>
                            <p className="mb-0 text-white font-weight-bold">Rock</p>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
