import React from "react"
import Img from "gatsby-image";
import styles from './benefits.module.scss'

export default function Benefits() {
    const data = [
        {
            title: 'Monetize your music',
            description: 'Monetize your followers turning them into real fans. We have provided the tools for artists to push the limits of their earnings potential with their music to succeed.'
        },
        {
            title: 'Bespoke Organic Listener, Streaming & Fan Growth Campaigns',
            description: 'On Bliiink, we leverage our network using gamification mechanics where fans can win rewards to drive exposure to artists and increase levels of engagement.'
        },
        {
            title: 'Social media shout outs and Blog spotlight',
            description: 'We Promote artists music on our social media pages and write articles to spotlight their work.'
        },
        {
            title: 'Cut out time wasting',
            description: 'You no longer need to pay for dead contacts and send tons of unending emails with little or no response. On Bliiink, you can get in touch with music professionals with a few clicks.'
        },
        {
            title: 'Submit music to blogs, playlists, and influencers that matter',
            description: 'Artists have direct access to relevant tastemakers and influencers to drive exposure. Artists can submit music to blogs, playlists, and influencers of choice for feedback and a shout-out across popular social media platforms.'
        },
        {
            title: 'Avoid bad actors',
            description: 'Tastemakers do not need to worry about falling into that category of bad actors that practice Payola. Tastemakers have a choice to accept or decline an artist\'s music. Importantly you get paid for your contribution to building an ethical environment for music development.'
        },
    ]
    return (
        <div className="container">
            <div className="text-center pb-lg-4 pt-3">
                <h2>Some benefits of joining <span className={`${styles.green}`}>B</span>liiink.</h2>
            </div>
            <div className="row">
                {data.map((item, index) =>
                    <div key={index} className="col-md-4 pb-5 mb-5">
                        <div key={index} className={`card ${styles.benefitCard}`}>
                            <div className="card-body">
                                <div className="py-2 py-md-0">
                                    <h5 className="py-3 text-center" style={{minHeight: '110px'}}><strong>{item.title}</strong></h5>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
