import {graphql, Link, useStaticQuery} from "gatsby";
import FeaturedBlog from "./featuredBlog";
import styles from "./imageSlider.module.scss";
import React from "react";

export default function ArtistSpotlightList() {
    const data = useStaticQuery(graphql`
        query {
            allWpPost(sort: { fields: [date], order: DESC }, limit: 6, filter: {categories: {nodes: {elemMatch: {id: {eq: "dGVybTozNg=="}}}}}) {
              nodes {
                title
                excerpt
                slug
                date(formatString: "MMMM DD, YYYY")
                featuredImage {
                  node {  
                    remoteFile {
                      childImageSharp {
                          fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid_tracedSVG
                          }
                        }
                    }
                  }  
                }
              }
            }
    }`)

    return (
        <section className="sixth-section py-5">
            <div className="container pt-5">
                <div className="text-center pb-lg-4 pt-3">
                    <h2>Artists Spotlight</h2>
                </div>
                <div className="row">
                    <FeaturedBlog articles={data.allWpPost.nodes}/>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <Link to="/artists-spotlight/">
                            <button type="button" className={`${styles.btn} btn btn-dark btn-lg`}>
                                Read More
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}